import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import HandshakeOutlined from '@mui/icons-material/HandshakeOutlined'
import SupportAgentOutlined from '@mui/icons-material/SupportAgentOutlined'
import BuildOutlined from '@mui/icons-material/BuildOutlined'
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined'
import VerifiedOutlined from '@mui/icons-material/VerifiedOutlined'

const reasons = [
  {
    icon: <HandshakeOutlined fontSize="large" sx={{ color: '#8faa30' }} />,  // Green icons
    title: 'Customer-Centered Approach',
    description: 'Your satisfaction is our priority. We listen to your needs and tailor our services to ensure you receive the best possible solution.',
  },
  {
    icon: <BuildOutlined fontSize="large" sx={{ color: '#8faa30' }} />,
    title: 'Innovative Design Solutions',
    description: 'We bring creativity and innovation to every project. Our team stays up-to-date with the latest trends and techniques to transform your outdoor space into a unique and stunning landscape.',
  },
  {
    icon: <AccessTimeOutlined fontSize="large" sx={{ color: '#8faa30' }} />,
    title: 'Timely Service',
    description: 'We respect your time and complete every project on schedule, ensuring your outdoor space is ready when you need it.',
  },
  {
    icon: <VerifiedOutlined fontSize="large" sx={{ color: '#8faa30' }} />,
    title: 'Trusted & Reliable',
    description: 'Our reputation is built on reliability and trust. We deliver consistent quality, backed by excellent customer service.',
  },
]

const WhyChooseUs = () => (
  <Box sx={{ px: 4, textAlign: 'center', bgcolor: 'white', color: '#015f44', mx: 'auto', borderRadius: '8px', px: { xs: 4, sm: 4, md: 8, lg: 8} }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#015f44', pt: 4 }}>
      Why Choose Us
    </Typography>

    <Grid container spacing={4} justifyContent="center">
      {reasons.map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {reason.icon}
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, color: '#015f44' }}>
              {reason.title}
            </Typography>
            <Box sx={{ borderBottom: 3, borderColor: '#8faa30', width: '120px', mx: 'auto', mb: 1 }} />
            <Typography variant="body2" sx={{ mt: 1, color: '#015f44' }}>
              {reason.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
)

export default WhyChooseUs
