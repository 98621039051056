import React, { useState } from 'react'
import { AppBar, Box, Button, Toolbar, Typography, Menu, MenuItem, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import './navbar.css'  // Import the CSS file
import logo from '../../readlandscaping.webp'
import CallIcon from '@mui/icons-material/Call'

const pages = [{ name: 'Services', link: '/services' }, { name: 'Portfolio', link: '/portfolio' }, { name: 'Contact', link: '/contact' }]

const Navbar = () => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (link) => {
    if (link) navigate(link)
    window.scrollTo(0, 0)
    setAnchorEl(null)
  }

  const navigateLink = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
  }

  return (
    <AppBar className="navbar" position="sticky">
      <Toolbar className="navbar">
        <Button className="navbar-logo" onClick={() => navigate('/')}>
          <img src={logo} alt="Read Landscaping" style={{ height: '48px', maxWidth: '100%' }} />
        </Button>
        <Box className="navbar-links">
          {pages.map(({ name, link }) => (
            <Button
              key={name}
              onClick={() => navigateLink(link)}
              className="navbar-button"
              sx={{ padding: { sm: '1px'}, mx: {sm: '4px'}}}
            >
              {name}
            </Button>
          ))}
        </Box>
        <Box className="navbar-contact">
          <CallIcon sx={{ color: 'white', my: 'auto', height: '32px', width: '32px' }} />
          <Typography className="contact-text" gutterBottom variant="h6" sx={{ color: 'white', textAlign: 'center', fontWeight: '700', my: 'auto' }}>
            +44 7580 198722
          </Typography>
        </Box>
        <IconButton 
          edge="end" 
          className="navbar-menu-button"
          onClick={handleMenuOpen} 
        >
          <MenuIcon sx={{ color: '#1c6d19' }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose(null)}
          disableScrollLock={true}
          sx={{
            '& .MuiPaper-root': {
              position: 'absolute',
              top: '64px', // Adjust based on the height of your AppBar
              right: 0,
              left: 0,
              maxWidth: '25vw', // Ensure the menu does not exceed viewport width
              overflowX: 'hidden', // Hide any horizontal overflow
            }
          }}
        >
          {pages.map(({ name, link }) => (
            <MenuItem key={name} onClick={() => handleMenuClose(link)} sx={{ color: '#8faa30'}}>
              {name}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
