import React from 'react'
import { Box, Grid, Typography, Card, CardMedia, Divider } from '@mui/material'
import ServicesHeader from './servicesHeader'

// Example image imports for the example section
import drivewayHeader from '../_content/driveway3.webp'
import drivewayExample1 from '../_content/driveway2.webp'
import drivewayExample2 from '../_content/driveway.webp'
import drivewayExample3 from '../_content/driveway1.webp'
import drivewayExample4 from '../_content/IMG_3317.webp'
import drivewayExample5 from '../_content/driveway4.webp'

const DrivewayServices = () => {
  return (
    <>
      <ServicesHeader background={drivewayHeader} text="Driveway Services by Read Landscaping" />
      <Box sx={{ backgroundColor: '#8faa30', mx: 'auto', padding: 2 }}>
        <Typography sx={{ color: 'white', textAlign: 'center', fontSize: '1.25rem', px: 4 }}>
          At Read Landscaping, we specialise in crafting exceptional driveways that enhance both the functionality and aesthetic appeal of your home. Based in Doddinghurst, we proudly serve the surrounding areas, delivering top-quality services to Brentwood, Ingatestone, Shenfield, and beyond.
        </Typography>
      </Box>
      <Box width={{ xs: 1, md: 0.7 }} sx={{ mx: 'auto' }}>
        <Typography sx={{ color: '#8faa30', textAlign: 'center', fontSize: '1.5rem', mt: 2 }}>
          Whether you're aiming to improve access to your home or need a reliable surface for parking, our experienced team is here to meet your needs.
        </Typography>
        <Typography sx={{ color: '#8faa30', textAlign: 'center', fontSize: '1.5rem', mt: 2 }}>
          When you choose Read Landscaping for your driveway project, you benefit from:
        </Typography>
        <Box sx={{ color: '#8faa30', textAlign: 'center', fontSize: '1.2rem', px: 4, mt: 2 }}>
          <ul>
            <li>
              <Typography sx={{ marginBottom: '1rem' }}>
                <strong>Durability and Longevity:</strong> We construct driveways designed to withstand the elements and heavy use, ensuring they remain in excellent condition for years to come.
              </Typography>
            </li>
            <li>
              <Typography sx={{ marginBottom: '1rem' }}>
                <strong>Enhanced Curb Appeal:</strong> A well-designed driveway can significantly boost the curb appeal of your property. We work with you to select the best materials and design that complement your home’s architecture.
              </Typography>
            </li>
            <li>
              <Typography sx={{ marginBottom: '1rem' }}>
                <strong>Low Maintenance Solutions:</strong> Our driveways are not only beautiful but also require minimal upkeep, saving you time and effort in the long run.
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>

      <Box width={{ xs: '90%', sm: '80%' }} sx={{ mx: 'auto', mt: 6 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mb: 8 }}>
          <Grid item xs={4} sm={4} md={4}>
            <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={drivewayExample1}
                alt="Driveway Example 1"
                sx={{ height: '300px', objectFit: 'cover' }}
              />
            </Card>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={drivewayExample2}
                alt="Driveway Example 2"
                sx={{ height: '300px', objectFit: 'cover' }}
              />
            </Card>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={drivewayExample3}
                alt="Driveway Example 3"
                sx={{ height: '300px', objectFit: 'cover' }}
              />
            </Card>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={drivewayExample4}
                alt="Driveway Example 4"
                sx={{ height: '300px', objectFit: 'cover' }}
              />
            </Card>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={drivewayHeader}
                alt="Driveway Example 5"
                sx={{ height: '300px', objectFit: 'cover' }}
              />
            </Card>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Card sx={{ borderRadius: '16px', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={drivewayExample5}
                alt="Driveway Example 6"
                sx={{ height: '300px', objectFit: 'cover' }}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ color: '#8faa30', border: '2px solid', mt: 2 }} />
    </>
  )
}

export default DrivewayServices
