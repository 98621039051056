import React from 'react'
import Slider from 'react-slick'
import { Box, Button, Typography } from '@mui/material'
import { NextArrow, PrevArrow } from './carouselArrows'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'
import header from '../../_content/paving4.webp'
import header2 from '../../_content/IMG_3317.webp'

const HomepageCarousel = ({ navigateLink }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  const images = [header, header2]

  return (
    <Box className="carousel-container">
      <Slider {...settings}>
        {images.map((img, index) => (
          <Box key={index} className="carousel-slide">
            <Box
              className="carousel-image"
              sx={{
                backgroundImage: `url(${img})`,
                height: { xs: '40vh', sm: '60vh', md: '80vh', lg: '80vh' },
              }}
            >
              <Box
                className="headerTypography"
                width={{ xs: '80%', sm: '80%', md: '60%', lg: '60%' }}
                sx={{ pl: { xs: 6, sm: 8, md: 20 }, pt: { xs: 10, sm: 12, md: 16 } }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: 'white',
                    fontWeight: 700,
                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                  }}
                >
                  Read Landscaping
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: 'white',
                    fontWeight: 700,
                    mt: 6,
                    fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' },
                  }}
                >
                  Professional landscaping and garden maintenance services, based in Essex
                </Typography>
                <Button
                  align="center"
                  size="large"
                  sx={{ backgroundColor: '#015f44', color: 'white', p: 1, textTransform: 'none', mx: 'auto', my: 2, '&:hover': {
                    backgroundColor: '#8faa30', // Light green color on hover
                  }}}
                  onClick={() => navigateLink('/contact')}
                >
                  Get a free quote today!
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  )
}

export default HomepageCarousel
