import React from 'react'
import HomepageCarousel from './components/carousel/homepageCarousel'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import './index.css'
import { ImageTextCard } from './components/cards/imageTextCard'
import turfing from './_content/turfing.webp'
import fencing from './_content/IMG_3320.webp'
import paving from './_content/IMG_3317.webp'
import driveway from './_content/driveway.webp' 
import Methodology from './components/homepageSplitter'
import ReviewCarousel from './components/carousel/reviewCarousel'
import ContactForm from './contact/contactForm'
import HomepageGallery from './components/homepageSplitter'
import { useNavigate } from 'react-router-dom'
import WhyChooseUs from './components/whyChooseUs'

const Homepage = () => {
  const navigate = useNavigate()

  const navigateLink = (link) => {
    navigate(link)
    window.scrollTo(0, 0)
  }

  return (
      <Box width={1} sx={{ mx: 'auto'}} >
        <HomepageCarousel navigateLink={() => navigateLink('/contact')}/>
        <Box sx={{ backgroundColor: '#8faa30', mx: 'auto', padding: 3}}>
          <Typography  sx={{color: 'white', textAlign: 'center', fontSize: '1.5rem' }}>
            We specialise in transforming and maintaining your outdoor space. Get in touch for a free quote.
          </Typography>
        </Box>
        <WhyChooseUs />
        <Box className="feature-cards">
          <Typography variant="h4" sx={{  fontWeight: '700', color: '#015f44', textAlign: 'center', mb: 2 }}>Some of Our Services</Typography>
          <Grid 
            container 
            spacing={{ xs: 2, md: 3 }} 
            columns={{ xs: 4, sm: 8, md: 12 }} 
            sx={{ mb: 2 }}
          >
            <Grid item xs={2} sm={4} md={3} key={0} className="grid-item">
              <ImageTextCard media={paving} header='Paving' />
            </Grid>
            <Grid item xs={2} sm={4} md={3} key={1} className="grid-item">
              <ImageTextCard media={turfing} header='Turfing'/>
            </Grid>
            <Grid item xs={2} sm={4} md={3} key={2} className="grid-item">
              <ImageTextCard media={fencing} header='Fencing' />
            </Grid>
            <Grid item xs={2} sm={4} md={3} key={3} className="grid-item">
              <ImageTextCard media={driveway} header='Driveways'/>
            </Grid>
            <Button
              align="center"
              size="large"
              sx={{ backgroundColor: '#015f44', color: 'white', p: 1, textTransform: 'none', mx: 'auto', my: 4, '&:hover': {
                backgroundColor: '#8faa30', // Light green color on hover
              }}}
              onClick={() => navigateLink('/services')}
            >
              View All Services
            </Button>
          </Grid>
        </Box>
        <Box>
          <HomepageGallery navigateLink={navigateLink} />
        </Box>
        <Box sx={{display: 'flex', backgroundColor: '#8faa30', flexDirection: { xs: 'column', md: 'row' }}}>
          <Box width={{ xs: 1, md: 0.5}}>
            <ReviewCarousel />
          </Box>
          <Divider orientation='vertical' flexItem sx={{ color: 'white', border: '1px solid' }} />
          <Box width={{ xs: 1, md: 0.5, backgroundColor: 'white' }}>
            <ContactForm />
          </Box>
        </Box>
      </Box>
  )
}

export default Homepage
